
const scrollAnimations = sal({
	threshold: 0.75,
});

var CloudUrl = "https://files.bookingonline.co.uk/image/upload/f_auto/themes/045/";

function loadSliders() {

	$('.popular-products__products').slick({
		centerMode: false,
		centerPadding: '0',
		slidesToShow: 3,
		prevArrow: "<button class='popular-products__button popular-products__button--prev' aria-label='Previous' type='button'><img src='https://bouncycastlenetwork-res.cloudinary.com/image/upload/v1633525959/themes/045/arrow.png' alt=''/></button>",
		nextArrow: "<button class='popular-products__button popular-products__button--next' aria-label='Previous' type='button'><img src='https://bouncycastlenetwork-res.cloudinary.com/image/upload/v1633525959/themes/045/arrow.png' alt=''/></button>",
		autoplay: true,
		autoplaySpeed: 5000,
		responsive: [
			{
				breakpoint: 1100,
				settings: {
					slidesToShow: 2
				}
			},
			{
				breakpoint: 650,
				settings: {
					slidesToShow: 1
				}
			}
		]
	});

	$('.clients__gallery').slick({
		centerMode: false,
		centerPadding: '0',
		slidesToShow: 5,
		arrows: false,
		autoplay: true,
		autoplaySpeed: 5000,
		responsive: [
			{
				breakpoint: 1000,
				settings: {
					slidesToShow: 3
				}
			},
			{
				breakpoint: 700,
				settings: {
					slidesToShow: 2
				}
			},
			{
				breakpoint: 400,
				settings: {
					slidesToShow: 1
				}
			}
		]
	});

	$('.testimonials__inner .carousel-inner').slick({
		centerMode: false,
		centerPadding: '0',
		slidesToShow: 3,
		arrows: false,
		autoplay: true,
		autoplaySpeed: 5000,
		responsive: [
			{
				breakpoint: 1100,
				settings: {
					slidesToShow: 2
				}
			},
			{
				breakpoint: 800,
				settings: {
					slidesToShow: 1
				}
			}
		]
	});

}

var sliderScript = document.getElementById("deferredSlickScript");

if (sliderScript) {
	sliderScript.addEventListener('load', function () {
		loadSliders();
	});
}


$(document).ready(function () {

	$("img.has-tooltip").tooltip({
		title: function () {
			return $(this).attr("alt");
		},
		placement: "auto"
	});

	$("a.has-tooltip").tooltip({
		placement: "auto"
	});

	// ADD SLIDEDOWN ANIMATION TO DROPDOWN //
	$('.toplinks').on('show.bs.dropdown', function (e) {
		$(this).find('.dropdown-menu').first().stop(true, true).slideDown();
		$("#TopLinksInner .dropdown-menu a").addClass("animated");
		window.scrollBy(0, 1);
	});

	// ADD SLIDEUP ANIMATION TO DROPDOWN //
	$('.toplinks').on('hide.bs.dropdown', function (e) {
		$(this).find('.dropdown-menu').first().stop(true, true).slideUp();
	});

	if ($('#HomepageMarker').length > 0) {


		$(".RotatorTestimonialContent a[itemprop='author']").each(function () {
			$(this)[0].nextSibling.nodeValue = '';
		});

		var promisesBgTrigger = document.getElementById("promises-bg-trigger");
		promisesBgTrigger.addEventListener('sal:in', () => {
			$('.promises').css("background-image", "url(" + CloudUrl + "promise-bg.jpg)");
		});

		var testimonialsBgTrigger = document.getElementById("testimonials-bg-trigger");
		testimonialsBgTrigger.addEventListener('sal:in', () => {
			$('.testimonials__bg').css("background-image", "url(" + CloudUrl + "testimonial-faces.jpg)");
		});

		var homeContactBgTrigger = document.getElementById("home-contact-bg-trigger");
		homeContactBgTrigger.addEventListener('sal:in', () => {
			$('.home-contact').css("background-image", "url(" + CloudUrl + "home-contact-bg.jpg)");
		});

		var lowerContentBgTrigger = document.getElementById("lower-content-bg-trigger");
		lowerContentBgTrigger.addEventListener('sal:in', () => {
			$('.home-lower-content__punchline-wrapper').css("background-image", "url(" + CloudUrl + "home-content-lower-bg.jpg)");
		});


		if ((window.matchMedia("(min-width: 768px)").matches)) {
			const bookingText = Splitting({ target: '.book-online__title', by: 'chars' });
			$('.book-online__title .char').each(function (i, el) {
				$(el).addClass('fadeInDown2').attr({ 'data-sal': '' }).css("animation-delay", "calc((1 + var(--char-index) / 40) * 1s)");
			});

			$('.footer__logo').addClass("fadeInDown").attr("data-sal", "");
			$('.main-content__photo').addClass("fadeIn").attr("data-sal", "");
			$('.home-lower-content__wave img').addClass("fadeInRight2").attr("data-sal", "");
			$('.home-lower-content__graphic img').addClass("fadeInRight2").attr("data-sal", "");
			$('.header__logo').addClass("fadeInDown").attr("data-sal", "");

			//Featured Categories
			$(".featured-categories__col").attr({ "data-sal": "" });
			$(".featured-categories__col:nth-child(4n + 1), .featured-categories__col:nth-child(4n + 2)")
				.addClass("fadeInLeft");
			$(".featured-categories__col:nth-child(4n + 3), .featured-categories__col:nth-child(4n + 4)")
				.addClass("fadeInRight");
			$(".featured-categories__col:nth-child(4n + 1), .featured-categories__col:nth-child(4n + 4)")
				.css("animation-delay", "0.3s");

			//Promises
			$('.promises__panel-wrapper').each(function (i, el) {
				$(el).addClass('fadeInUp2').css('animation-delay', i / 3 + 's').attr('data-sal', '');
			});

			//HowToBook
			$('.how-to-book__panel').each(function (i, el) {
				$(el).addClass(' fadeInRight2').attr('data-sal', '').css('animation-delay', i / 3 + 's');
			});

			//CTA
			const ctaText = Splitting({ target: '.homepage-cta__text p', by: 'chars' });
			$('.homepage-cta__text p .char').each(function (i, el) {
				$(el).addClass('zoomIn').attr('data-sal', '').css({ 'animation-delay': i / 40 + 's', 'animation-duration': '1.25s' });
			});

		} else {
			$('.header__logo').css('visibility', 'visible');
		}
	} else {
		$('.header__logo').css('visibility', 'visible');
	}

	function fixHeights() {
		$('.testimonials .item').sameHeight();
		$('.featured-categories__title').sameHeight();
		$('.promises__title').sameHeight();
		$('.promises__panel').sameHeight();
		$('.featured-categories__subtitle').sameHeight();
	}

	setTimeout(fixHeights, 300);

	$(window).on('resize',
		function () {
			setTimeout(fixHeights, 300);
		});

	scrollAnimations.update();

});